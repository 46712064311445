<template>
  <div class="contract-confirm">
    <!-- 查询条件区域 -->
    <div class="search-container">
      <div class="search-form">
        <div class="search-item">
          <van-field
            v-model="selectedShip"
            readonly
            clickable
            label=""
            placeholder="选择船名"
            @click="showShipPicker = true"
            class="custom-field"
          />
          <van-popup v-model="showShipPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="shipList"
              @confirm="onShipConfirm"
              @cancel="showShipPicker = false; selectedShip = ''"
            />
          </van-popup>
        </div>
        <div class="search-item">
          <van-field
            v-model="selectedVoyage"
            readonly
            clickable
            label=""
            placeholder="选择航次"
            @click="showVoyagePicker = true"
            class="custom-field"
          />
          <van-popup v-model="showVoyagePicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="voyageList"
              @confirm="onVoyageConfirm"
              @cancel="showVoyagePicker = false; selectedVoyage = ''"
            />
          </van-popup>
        </div>
      </div>
    </div>

    <div v-if="filteredDataList.length > 0" class="contract-confirm">
      <div v-for="(group, index) in filteredDataList" :key="index" class="data-row">
        <div class="contract-card">
          <div class="contract-header">
            <h3>合同详情</h3>
            <div class="download-btns">
              <div v-for="(item, itemIndex) in group.items" :key="itemIndex" class="download-btn" @click="downloadFile(item, group)">
                <i class="el-icon-download"></i>
                <span>附件{{itemIndex + 1}}</span>
              </div>
            </div>
          </div>
          <div class="contract-content">
            <div class="info-section">
              <div class="info-group">
                <div class="info-item">
                  <span class="label">船名</span>
                  <span class="value">{{ group.items[0].shipName || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">航线</span>
                  <span class="value">{{ group.items[0].voyage || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">离港时间</span>
                  <span class="value">{{ group.items[0].leavetime || '-' }}</span>
                </div>
              </div>
              
              <div class="info-group">
                <div class="info-item">
                  <span class="label">客户名称</span>
                  <span class="value">{{ group.items[0].customerName || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">组办部门</span>
                  <span class="value">{{ group.items[0].busgrpName || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">立项方式</span>
                  <span class="value">{{ group.items[0].lxfs || '-' }}</span>
                </div>
              </div>

              <div class="info-group">
                <div class="info-item">
                  <span class="label">创建人员</span>
                  <span class="value">{{ group.items[0].crePers || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">创建时间</span>
                  <span class="value">{{ group.items[0].creTime || '-' }}</span>
                </div>
                <div class="info-item">
                  <span class="label">双章回传</span>
                  <span class="value">{{ group.items[0].szhc || '-' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="contract-footer">
            <button :class="group.allDownloaded ? 'confirm-btn' : 'gray-btn'" @click="updateDataApi(group)">确认合同</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty-state">
        <i class="el-icon-document"></i>
        <p>暂无合同数据</p>
        <span>当前没有需要确认的合同文件</span>
      </div>
  </div>
</template>

<script>
import { fetchDataAndFiles, updateData } from '@/api/warningPanel';
import { Field, Popup, Picker } from 'vant';

export default {
  name: 'ContractConfirm',
  components: {
    'van-field': Field,
    'van-popup': Popup,
    'van-picker': Picker
  },
  data() {
    return {
      dataList: [],
      groupedDataList: [],
      selectedShip: '',
      selectedVoyage: '',
      shipList: [],
      voyageList: [],
      showShipPicker: false,
      showVoyagePicker: false,
      // 新增一个变量来存储初始化的数据源
      initialDataList: [] 
    };
  },
  computed: {
    filteredDataList() {
      if (!this.selectedShip && !this.selectedVoyage) {
        return this.groupedDataList;
      }
      return this.groupedDataList.filter(group => {
        const item = group.items[0];
        const matchShip = !this.selectedShip || item.shipName === this.selectedShip;
        const matchVoyage = !this.selectedVoyage || item.voyage === this.selectedVoyage;
        return matchShip && matchVoyage;
      });
    }
  },
  methods: {
    isImage(filename) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
      return imageExtensions.some(ext => filename.toLowerCase().endsWith(ext));
    },
    isPdf(filename) {
      return filename.toLowerCase().endsWith('.pdf');
    },
    fetchDataAndFiles() {
      fetchDataAndFiles({
        userName: this.$store.getters.userId,
        shipName: this.selectedShip,
        voyage: this.selectedVoyage
      }).then(res => {
        console.log(res);
        this.dataList = res;
        this.initialDataList = res; // 保存初始化数据源
        this.groupData();
        this.initFilterOptions();
      }).catch(err => {
        console.log(err);
      });
    },
    initFilterOptions() {
      // 初始化船名和航次选项
      const ships = new Set();
      const voyages = new Set();
      this.initialDataList.forEach(item => {
        if (item.shipName) ships.add(item.shipName);
        if (item.voyage) voyages.add(item.voyage);
      });
      this.shipList = Array.from(ships);
      this.voyageList = Array.from(voyages);
    },
    applyFilter() {
      this.fetchDataAndFiles();
    },
    resetFilter() {
      this.selectedShip = '';
      this.selectedVoyage = '';
      this.fetchDataAndFiles();
    },
    groupData() {
      // 按agentcon_no分组
      const groups = {};
      this.dataList.forEach(item => {
        if (!groups[item.agentcon_no]) {
          groups[item.agentcon_no] = {
            items: [],
            downloadedCount: 0,
            allDownloaded: false
          };
        }
        groups[item.agentcon_no].items.push({...item, downloaded: false});
      });
      this.groupedDataList = Object.values(groups);
      console.log(this.groupedDataList);
    },
    updateDataApi(group) {
      if (!group.allDownloaded) {
        this.$message({
          message: '请先下载查看所有合同文件',
          type: 'warning'
        });
        return;
      }
      updateData(group.items[0].agentcon_no).then(res => {
        console.log(res);
        // 从分组数据列表中移除当前组
        const index = this.groupedDataList.findIndex(g => g === group);
        if (index > -1) {
          this.groupedDataList.splice(index, 1);
        }
        this.$message({
          message: '合同确认成功',
          type: 'success'
        });
      }).catch(err => {
        console.log(err);
        this.$message.error('合同确认失败');
      });
    },
    downloadFile(item, group) {
      if (!item.downloaded) {
        item.downloaded = true;
        group.downloadedCount++;
        if (group.downloadedCount === group.items.length) {
          group.allDownloaded = true;
        }
      }
      window.open("https://bulk-hetong.oss-cn-shanghai.aliyuncs.com/" + item.fileName);
    },
    onShipConfirm(value) {
      this.selectedShip = value;
      this.showShipPicker = false;
      this.updateVoyageList();
      this.fetchDataAndFiles();
    },
    onVoyageConfirm(value) {
      this.selectedVoyage = value;
      this.showVoyagePicker = false;
      this.fetchDataAndFiles();
    },
    updateVoyageList() {
      const voyages = new Set();
      // 使用初始化的数据源来更新航次列表
      this.initialDataList.forEach(item => {
        if (item.shipName === this.selectedShip && item.voyage) {
          voyages.add(item.voyage);
        }
      });
      this.voyageList = Array.from(voyages);
    }
  },
  mounted() {
    this.fetchDataAndFiles();
  }
};
</script>

<style scoped>
.contract-confirm {
  background: #f5f7fa;
  min-height: 100vh;
  padding: 20px;
}

.search-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  padding: 24px;
  margin-bottom: 20px;
}

.search-form {
  display: flex;
  align-items: center;
  gap: 20px;
}

.search-item {
  flex: 1;
}

.custom-field .van-field__control {
  background: #f5f7fa;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #dcdfe6;
}

.custom-field .van-field__control:focus {
  border-color: #409eff;
}

.contract-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  padding: 24px;
  margin-bottom: 20px;
}

.contract-header {
  border-bottom: 1px solid #ebeef5;
  padding-bottom: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contract-header h3 {
  color: #303133;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.download-btns {
  display: flex;
  gap: 10px;
}

.download-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #409eff;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s;
}

.download-btn:hover {
  background: #ecf5ff;
}

.download-btn i {
  margin-right: 6px;
}

.contract-content {
  margin-bottom: 24px;
}

.info-section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.info-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  color: #909399;
  font-size: 14px;
}

.value {
  color: #303133;
  font-size: 14px;
  font-weight: 500;
}

.contract-footer {
  text-align: center;
  padding-top: 24px;
  border-top: 1px solid #ebeef5;
}

.confirm-btn {
  background: #409eff;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}

.confirm-btn:hover {
  background: #66b1ff;
}

.file-name {
  font-size: 14px;
  color: #606266;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-size {
  font-size: 12px;
  color: #909399;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

.empty-state i {
  font-size: 48px;
  color: #909399;
  margin-bottom: 16px;
}

.empty-state p {
  font-size: 20px;
  color: #303133;
  margin: 0 0 8px;
}

.empty-state span {
  font-size: 14px;
  color: #909399;
}

.gray-btn {
  background-color: gray;
  cursor: not-allowed;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  color: #fff;
}
</style>